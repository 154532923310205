import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import YAML from 'yaml';

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // Pour pouvoir utiliser des fichiers yaml
  .use(Backend)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    // Namespaces : https://www.i18next.com/translation-function/essentials#accessing-keys-in-different-namespaces
    ns: ['contracts', 'common', 'auth'],
    /** @todo common contiendra les traductions classiques : boutons etc. , à créer ! */
    defaultNS: 'common',
    backend: {
      // load translation using http -> see /public/locales
      loadPath: '/locales/{{lng}}/{{ns}}.yaml',
      parse(data) {
        return YAML.parse(data);
      },
    },
    debug: true,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    lng: 'fr',
  });

export default i18n;
