import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Checkbox from '@material-ui/core/Checkbox';
import { Button, TextField } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import MediaObjectService from '../../service/mediaObjectService';

/**
 * @param {Object} props
 * @param {Array<MediaObject>} [props.values]
 * @param {any} [props.onChange]
 * @param {boolean} [props.multi]
 */
const UploadMediaObject = (props) => {
  // eslint-disable-next-line react/prop-types
  const { onChange, values = [], multi = true } = props;

  const { t } = useTranslation();

  const [fileToUpload, setFileToUpload] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [message, setMessage] = useState('');
  const [fileInfos, setFileInfos] = useState(values);

  useEffect(() => {
    onChange(fileInfos);
  }, [fileInfos]);

  useEffect(() => {
    if (fileToUpload.length && fileInfos.length && !multi) setMessage("On ne peut associer qu'un seul fichier. _A TRADUIRE_");
  }, [fileToUpload]);

  const addFileToUpload = (event) => {
    setFileToUpload(event.target.files);
  };

  const upload = async () => {
    setUploading(true);
    const currentFile = fileToUpload[0];
    setProgress(0);
    try {
      const newFile = await MediaObjectService.upload(currentFile, (event) => {
        setProgress(Math.round((100 * event.loaded) / event.total));
      });
      setMessage('Fichier téléchargé avec succès');
      setFileInfos([...fileInfos, newFile]);
    } catch (error) {
      setProgress(0);
      setMessage(`Téléchargement impossible! ${error.message}`);
    }
    setUploading(false);
    setFileToUpload([]);
  };

  const deleteFileId = async (id) => {
    await MediaObjectService.delete(id);
    const newList = fileInfos.filter((d) => d.id !== id);
    setFileInfos(newList);
  };

  return (
    <div>
      {uploading && (
        <div className="progress">
          <div
            className="progress-bar progress-bar-info progress-bar-striped"
            role="progressbar"
            aria-valuenow={progress}
            aria-valuemin="0"
            aria-valuemax="100"
            style={{ width: `${progress}%` }}
          >
            {progress}
            %
          </div>
        </div>
      )}
      <TextField
        autoFocus
        margin="dense"
        type="file"
        id="addFileToUpload"
        helperText="Sélectionner un fichier"
        label={t('contracts:field.file')}
        // fullWidth
        onChange={addFileToUpload}
      />
      <Button
        className="btn btn-success"
        // disabled={!fileToUpload || (fileInfos.length && !multi)}
        onClick={upload}
      >
        Téléverser
      </Button>
      <div className="alert alert-light" role="alert">
        {message}
      </div>
      <div className="card">
        <div className="card-header">Liste des fichiers</div>
        <ul className="list-group list-group-flush">
          {fileInfos.map((data) => (
            <li className="list-group-item" key={data.id}>
              <Checkbox color="default" />
              {data.filePath}
              {data.id}
              <Button
                color="secondary"
                size="small"
                startIcon={<DeleteIcon />}
                onClick={() => deleteFileId(data.id)}
              />
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default UploadMediaObject;
