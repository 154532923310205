import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTranslation } from 'react-i18next';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import ContractService from '../../service/contractService';
import { Contract, IContract } from '../../resource/contract';
import UploadMediaObject from '../UploadFile/UploadMediaObject';
import { MediaObject } from '../../resource/mediaObject';

interface IContractCreateForm {
  contract: IContract;
  onChange: (contract: IContract) => void;
}

// Stateless, sert à afficher un form pour Contract
export const ContractCreateForm = (props: IContractCreateForm) => {
  const { contract, onChange } = props;

  /** Modifie le state du parent quand on tape des infos dans le tableau */
  const handle = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    val: number | File | undefined = undefined,
  ) => {
    /**
     * sort les propriétés de l'objet
     * ...data = [ num = data.num, tag = data.tag etc.]
     */
    const newdata = new Contract({
      ...contract,
      [e.target.id]: val !== undefined ? val : e.target.value,
    });
    onChange(newdata);
  };

  const { t } = useTranslation();

  return (
    <>
      <TextField
        autoFocus
        margin="dense"
        id="num"
        label={t('contracts:field.num')}
        value={contract.num}
        fullWidth
        onChange={(event) => handle(event, parseInt(event.target.value, 10))}
      />
      <TextField
        autoFocus
        margin="dense"
        id="tag"
        label="Tag"
        value={contract.tag}
        fullWidth
        onChange={(e) => handle(e)}
      />
      <TextField
        autoFocus
        margin="normal"
        type="date"
        id="beginDate"
        InputLabelProps={{ shrink: true }}
        label={t('contracts:field.beginDate')}
        value={contract.beginDate}
        fullWidth
        onChange={(e) => handle(e)}
      />
      <TextField
        autoFocus
        margin="normal"
        type="date"
        id="endDate"
        InputLabelProps={{ shrink: true }}
        label={t('contracts:field.endDate')}
        value={contract.endDate}
        fullWidth
        onChange={(e) => handle(e)}
      />
      <CurrencyTextField
        margin="dense"
        id="price"
        currencySymbol="€"
        outputFormat="string"
        digitGroupSeparator=""
        label={t('contracts:field.price')}
        value={contract.price?.toString()}
        fullWidth
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handle(e)}
      />
      <UploadMediaObject
        values={contract.file && [contract.file]}
        onChange={(files: Array<MediaObject>) => {
          const contractFile = files?.[0];
          const c = { ...contract, file: contractFile };
          onChange(c);
        }}
        multi={false}
      />
    </>
  );
};

interface IProps {
  contract: IContract;
  open: boolean;
  setOpen: (open: boolean) => void;
  onCreate: (contract: IContract) => void;
}

export const ContractCreateModal = (props: IProps) => {
  const { contract, open, setOpen, onCreate } = props;

  const [data, setData] = useState<IContract>(contract);
  const handleClose = () => {
    setOpen(false);
  };

  /**
   * Ajout du contrat
   */
  const onSubmit = async () => {
    const created = await ContractService.create(data);
    onCreate(created);
    // pour vider le formulaire
    setData(new Contract([]));
    handleClose();
  };

  const { t } = useTranslation();
  return (
    <>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{t('common:action.create')}</DialogTitle>
        <DialogContent>
          <ContractCreateForm contract={data} onChange={setData} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {t('common:action.close')}
          </Button>
          <Button onClick={onSubmit} color="primary">
            {t('common:action.add')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
