import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTranslation } from 'react-i18next';
import ContractService from '../../service/contractService';
import { IContract } from '../../resource/contract';

interface IContractDeleteForm {
  contract: IContract;
}

// Stateless, sert à afficher un form d'update pour Contract
export const ContractDeleteForm = (props: IContractDeleteForm) => {
  const { contract } = props;

  const { t } = useTranslation();

  return (
    <>
      <TextField
        autoFocus
        margin="dense"
        id="num"
        label={t('contracts:field.num')}
        value={contract.num}
        fullWidth
      />
      <TextField autoFocus margin="dense" id="tag" label="Tag" value={contract.tag} fullWidth />
      <TextField
        autoFocus
        margin="dense"
        type="string"
        id="beginDate"
        InputLabelProps={{ shrink: true }}
        label={t('contracts:field.beginDate')}
        value={contract.beginDate}
        fullWidth
      />
      <TextField
        autoFocus
        margin="dense"
        type="string"
        id="endDate"
        InputLabelProps={{ shrink: true }}
        label={t('contracts:field.endDate')}
        value={contract.endDate}
        fullWidth
      />
      <TextField
        autoFocus
        margin="dense"
        id="price"
        label={t('contracts:field.price')}
        type="number"
        value={contract.price}
        fullWidth
      />
      {/** @todo Remplacer par une checkbox qui mentionne la présence d'un fichier? */}
      <TextField
        autoFocus
        margin="dense"
        id="filePath"
        label={t('contracts:field.file')}
        value={contract.file.filePath}
        fullWidth
      />
    </>
  );
};

interface IProps {
  contract: IContract;
  open: boolean;
  setOpen: (open: boolean) => void;
  onDelete: (contract: IContract) => void;
}

export const ContractDeleteModal = (props: IProps) => {
  const { contract, open, setOpen, onDelete } = props;

  const handleClose = () => {
    setOpen(false);
  };

  /**
   * Suppression du contrat
   */
  const onSubmit = async () => {
    await ContractService.delete(contract);
    onDelete(contract);
    handleClose();
  };

  const { t } = useTranslation();
  return (
    <>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{t('contracts:label.title.delete')}</DialogTitle>
        <DialogContent>
          <ContractDeleteForm contract={contract} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {t('common:action.cancel')}
          </Button>
          <Button onClick={onSubmit} color="primary">
            {t('common:action.delete')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
