import { Route, Routes } from 'react-router-dom';
import React from 'react';
import Login from './LoginComponent';
import Register from './RegisterComponent';

function Auth() {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/auth/signin" element={<Login />} />
      <Route path="/auth/signup" element={<Register />} />
    </Routes>
  );
}

export default Auth;
