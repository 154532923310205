import React, { Suspense, useEffect, useState } from 'react';
import { authService } from './config/axios';
import Main from './layout/Main';
import Auth from './component/Auth/Auth';

interface IAppProps {
  children: any;
}

export const App = (props: IAppProps) => {
  /** Décomposition des props */
  const { children = null } = props;

  /** Gestion de l'identification et déconnexion de l'utilisateur  */
  const [currentUser, setCurrentUser] = useState(undefined);

  useEffect(() => {
    const user = authService.getCurrentToken();
    if (user) {
      setCurrentUser(user);
    }
  }, []);

  return <>{currentUser ? <Main>{children}</Main> : <Auth />}</>;
};

// loading component for suspense fallback
const Loader = () => (
  <div className="App">
    {/*     <img src={logo} className="App-logo" alt="logo" />
     */}
    <div>loading...</div>
  </div>
);

const AppWithSuspense = (props: IAppProps) => {
  const { children } = props;
  return (
    <Suspense fallback={<Loader />}>
      <App>{children}</App>
    </Suspense>
  );
};
export default AppWithSuspense;
