import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import '@fortawesome/fontawesome-free/css/all.css';
import * as serviceWorker from './serviceWorker';

import App from './App';
import Dashboard from './Dashboard';
import ContractList from './component/Contract/ContractList';
import './i18n';

ReactDOM.render(
  // <Provider store={store}>
  <BrowserRouter>
    <App>
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/contracts" element={<ContractList />} />
      </Routes>
    </App>
    {/*         <Route render={() => <h1>Not Found</h1>} />
       */}
  </BrowserRouter>,
  // </Provider>
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
