import { AxiosResponse } from 'axios';
import axios from '../config/axios';
import { IContract } from '../resource/contract';
import downloadFile from '../utils/downloadFile';

const endpoint = 'contracts';

const handleErrorStatuses = (res: AxiosResponse) => {
  /** @todo que faire ? */
  const error = res.status === 400 || res.status === 404 || res.status === 500;
  return error;
};

class ContractService {
  /** Mise à jour du contrat en back */
  update = async (contract: IContract) => {
    const data = new FormData();
    Object.entries(contract).forEach(([k, v]) => data.append(k, v));
    // si on enlève pas id il considère que c'est un upload...
    data.delete('licenses');
    data.delete('vendors');
    data.delete('developers');
    data.delete('softwares');

    if (!contract.file) data.delete('file');
    const res = await axios.put(`${endpoint}/${contract.id}`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    const newContract = res.data;
    return newContract;
  };

  /** Get one contract */
  getOne = async (id: number) => {
    const res = await axios.get(`${endpoint}/${id}`, {});
    if (handleErrorStatuses(res)) {
      return res;
    }
    const newContract = res.data;
    return newContract;
  };

  /** liste des contracts */
  get = async () => {
    const res = await axios.get(`${endpoint}`, {});
    if (handleErrorStatuses(res)) {
      return res;
    }
    const getContracts = res.data['hydra:member'];
    return getContracts;
  };

  /** Mise à jour du contrat en back */
  create = async (contract: IContract) => {
    const res = await axios.post(`${endpoint}`, { ...contract, file: contract.file?.['@id'] });
    if (handleErrorStatuses(res)) {
      return res;
    }
    const newContract = res.data;
    return newContract;
  };

  /** Supprimer un contract */
  delete = async (contract: IContract) => {
    const res = await axios.delete(`${endpoint}/${contract.id}`);
    return res.data;
  };

  /** Renvoi l'Url de téléchargement */
  download = (contract: IContract) => {
    const res = contract?.file?.contentUrl;
    return res && downloadFile(res);
  };
}
export default new ContractService();
