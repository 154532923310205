import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTranslation } from 'react-i18next';

import ContractService from '../../service/contractService';
import { IContract } from '../../resource/contract';

interface IContractUpdateForm {
  contract: IContract;
  onChange: (contract: IContract) => void;
}

// Stateless, sert à afficher un form d'update pour Contract
export const ContractUpdateForm = (props: IContractUpdateForm) => {
  const { contract, onChange } = props;

  /** Modifie le state du parent quand on tape des infos dans le tableau */
  const handle = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    val: number | File | undefined = undefined,
  ) => {
    /**
     * sort les propriétés de l'objet
     * ...data = [ num = data.num, tag = data.tag etc.]
     */
    const newdata = { ...contract, [e.target.id]: val !== undefined ? val : e.target.value };
    onChange(newdata);
  };

  const { t } = useTranslation();

  return (
    <>
      <TextField
        autoFocus
        margin="dense"
        id="num"
        label={t('contracts:field.num')}
        value={contract.num}
        fullWidth
        // onChange={(e) => handle(e)}
        onChange={(event) => handle(event, parseInt(event.target.value, 10))}
      />
      <TextField
        autoFocus
        margin="dense"
        id="tag"
        label="Tag"
        value={contract.tag}
        fullWidth
        onChange={(e) => handle(e)}
      />
      <TextField
        autoFocus
        margin="dense"
        type="date"
        id="beginDate"
        InputLabelProps={{ shrink: true }}
        label={t('contracts:field.beginDate')}
        value={contract.beginDate}
        fullWidth
        onChange={(e) => handle(e)}
      />
      <TextField
        autoFocus
        margin="dense"
        type="date"
        id="endDate"
        InputLabelProps={{ shrink: true }}
        label={t('contracts:field.endDate')}
        value={contract.endDate}
        fullWidth
        onChange={(e) => handle(e)}
      />
      <TextField
        autoFocus
        margin="dense"
        id="price"
        label={t('contracts:field.price')}
        value={contract.price}
        fullWidth
        onChange={(e) => handle(e, parseInt(e.target.value, 10))}
      />
      <TextField
        autoFocus
        margin="dense"
        type="file"
        id="file"
        label={t('contracts:field.file')}
        helperText="Sélectionner un fichier"
        fullWidth
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handle(e, e.target.files?.[0])}
      />
    </>
  );
};

interface IProps {
  contract: IContract;
  open: boolean;
  setOpen: (open: boolean) => void;
  onUpdate: (contract: IContract) => void;
}

export const ContractUpdateModal = (props: IProps) => {
  const { contract, open, setOpen, onUpdate } = props;

  const [data, setData] = useState<IContract>(contract);

  const handleClose = () => {
    setOpen(false);
  };

  /**
   * Update du contrat
   */
  const onSubmit = async () => {
    const updated = await ContractService.update(data);
    onUpdate(updated);
    handleClose();
  };

  const { t } = useTranslation();

  return (
    <>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{t('contracts:label.title.update')}</DialogTitle>
        <DialogContent>
          <ContractUpdateForm contract={data} onChange={setData} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {t('common:action.close')}
          </Button>
          <Button onClick={onSubmit} color="primary">
            {t('common:action.update')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
