import { MediaObject } from './mediaObject';

export interface IContract {
  id: string;
  /** Numéro du contrat */
  num: number;
  tag: string;
  /** Date au format YYYY-mm-dd */
  beginDate: string;
  /** Date au format YYYY-mm-dd */
  endDate: string;
  price: number;
  file: MediaObject;
}

export class Contract implements IContract {
  id: string;

  /** Numéro du contrat */
  num: number;

  tag: string;

  /** Date au format YYYY-mm-dd */
  beginDate: string;

  /** Date au format YYYY-mm-dd */
  endDate: string;

  price: number;

  /** MediaObject is completely sent by the backend, not just its iri */
  file: MediaObject;

  constructor(data: any) {
    this.num = data.num && parseInt(data.num, 10);
    this.price = data.price && parseFloat(data.price);
    // Object.entries(data).forEach(([key,value]) => this[key] = value);
    this.id = data.id;
    this.tag = data.tag;
    this.beginDate = data.beginDate;
    this.endDate = data.endDate;
    this.file = data.file;
  }
}
