import { AxiosInstance } from 'axios';

class AuthService {
  axios: AxiosInstance;

  constructor(axios: AxiosInstance) {
    this.axios = axios;
  }

  /** Créer un compte */
  register = async (email: string, password: string) => {
    const res = await this.axios.post('auth/signup', {
      email,
      password,
    });
    return res;
  };

  /**
   * Connexion à un compte
   *  stocke le token JWT dans localStorage
   * @todo rajouter le cas d'erreur de login
   */
  login = async (email: string, password: string) => {
    const res = await this.axios.post('auth/signin', {
      password,
      email,
    });
    if (res.data.token) {
      localStorage.setItem('tokenDetail', JSON.stringify(res.data.token));
    }
    return res.data.token;
  };

  /** Déconnexion */
  logout = () => {
    localStorage.removeItem('tokenDetail');
  };

  /**
   * Etat de la présence d'un token en localStorage
   */
  isLogged = (): boolean => {
    const token = localStorage.getItem('tokenDetail');
    return !!token;
  };

  getCurrentToken = () => {
    const tokenStr = localStorage.getItem('tokenDetail');
    if (tokenStr) return JSON.parse(tokenStr);

    return null;
  };

  /**
   * Renvoie le header à partir du user stocké dans le local storage
   */
  genAuthHeader = () => {
    const tokenStr = localStorage.getItem('tokenDetail');
    const token = tokenStr ? JSON.parse(tokenStr) : null;

    if (token) {
      return { Authorization: `Bearer ${token}` };
    }
    return { Authorization: 'Erreur' };
  };
}

export default AuthService;
