import { AxiosRequestConfig } from 'axios';
import axios from '../config/axios';
import { MediaObject } from '../resource/mediaObject';

const endpoint = 'media_objects';

class MediaObjectService {
  /** Upload media files */
  upload = async (
    file: File,
    onUploadProgress: AxiosRequestConfig['onUploadProgress'],
  ): Promise<MediaObject> => {
    const formData = new FormData();
    formData.append('file', file);
    const res = await axios.post(endpoint, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress,
    });
    const newFile = res.data;
    return newFile;
  };

  getOne = async (id: number): Promise<MediaObject> => {
    const res = await axios.get(`${endpoint}/${id}`, {});
    /**
     * @todo
     * if (handleErrorStatuses(res)) {
      return res;
    } */
    const newMediaObject = new MediaObject(res?.data);
    return newMediaObject;
  };

  get = async (): Promise<Array<MediaObject>> => {
    const res = await axios.get(endpoint);
    // console.log(new MediaObject(res.data['hydra:member'][0]));
    return res.data['hydra:member'].map((item: object) => new MediaObject(item));
  };

  delete = async (id: string | number) => axios.delete(`${endpoint}/${id}`);
}

export default new MediaObjectService();
