export interface IMediaObject {
  '@id': string;
  '@type': string;
  id: string;
  file: File;
  /** rajouté par le FileNormalizer en back */
  contentUrl: string;
  filePath: string;
}

export class MediaObject implements IMediaObject {
  '@id': string;

  '@type': string;

  id: string;

  file: File;

  /** rajouté par le Filenormalizer en back */
  contentUrl: string;

  filePath: string;

  constructor(data: any) {
    this['@id'] = data['@id'];
    this['@type'] = data['@type'];
    this.id = data.id;
    this.file = data.file;
    this.contentUrl = data.contentUrl;
    this.filePath = data.filePath;
  }
}
