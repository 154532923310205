import axios from 'axios';
import { ENTRYPOINT } from './entrypoint';
import AuthService from '../service/authService';

const instance = axios.create({
  baseURL: ENTRYPOINT,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const authService = new AuthService(instance);

/**
 * Intercepteur pour le token
 */
instance.interceptors.request.use((request) => {
  if (authService.isLogged()) {
    request.headers.Authorization = authService.genAuthHeader().Authorization;
  }
  return request;
});

// Add a response interceptor
instance.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response.status === 401) {
      console.log('erreuuuuur 401');
      localStorage.clear();
      window.location.href = '/auth/signin';
    }
  },
);

export default instance;
