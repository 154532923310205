import { ENTRYPOINT } from '../config/entrypoint';

/**
 * path = chemin vers le fichier que l'on souhaite télécharger
 * (il ne contient pas l'URL entière)
 * @todo pour l'instant on ne garde que le path relatif au front car public du back semble
 *       être accessible directement. Il faut s'assurer que c'est ce que l'on souhaite.
 *       sinon reconcaténer avec ENTRYPOINT
 * @see ContractService exemple /media/contracts/xxx.pdf
 */
export default (path: string) => `${path}`;
