import React, { useEffect, useState } from 'react';
import { DataGrid, GridCellParams, GridColumns, GridSelectionModel } from '@material-ui/data-grid';
import { Button, ButtonGroup, Paper } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import Fab from '@material-ui/core/Fab';
import { useTranslation } from 'react-i18next';
import Moment from 'react-moment';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import ContractService from '../../service/contractService';
import { ContractUpdateModal } from './ContractUpdate';
import { ContractDeleteModal } from './ContractDelete';
import { ContractCreateModal } from './ContractCreate';
import { Contract, IContract } from '../../resource/contract';
import { useStyles } from '../../layout/Main';

interface IContractRowButtonsProps {
  contract: IContract;
  onUpdate: (contract: IContract) => void;
  onDelete: (contract: IContract) => void;
}

const ContractRowButtons = ({ contract, onUpdate, onDelete }: IContractRowButtonsProps) => {
  const [showUpdateModal, setShowUpdateModal] = useState(false);

  const onUpdateAndClose = (data: IContract) => {
    /**
     * @todo On procède comme ça pour pouvoir (si besoin) attendre que le onUpdate
     * finisse avant qu'on ferme la modal, voir comment faire si onUpdate est un state setter
     */
    onUpdate(data);
    setShowUpdateModal(false);
  };

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const onDeleteAndClose = (data: IContract) => {
    onDelete(data);
    setShowDeleteModal(false);
  };
  return (
    <>
      <ButtonGroup>
        <Button
          variant="contained"
          color="primary"
          size="small"
          startIcon={<EditIcon />}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setShowUpdateModal(true);
          }}
        />

        <ContractUpdateModal
          contract={contract}
          open={showUpdateModal}
          setOpen={setShowUpdateModal}
          onUpdate={onUpdateAndClose}
        />

        <Button
          variant="contained"
          color="secondary"
          size="small"
          startIcon={<DeleteIcon />}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setShowDeleteModal(true);
          }}
        />
      </ButtonGroup>

      <ContractDeleteModal
        contract={contract}
        open={showDeleteModal}
        setOpen={setShowDeleteModal}
        onDelete={onDeleteAndClose}
      />
    </>
  );
};

interface IContractCreateButtonProps {
  onCreate: (contract: IContract) => void;
}
const ContractCreateButton = ({ onCreate }: IContractCreateButtonProps) => {
  const newContract = new Contract([]);
  const [showCreateModal, setShowCreateModal] = useState(false);

  const onCreateAndClose = (data: IContract) => {
    /**
     * @todo On procède comme ça pour pouvoir (si besoin) attendre que le onUpdate
     * finisse avant qu'on ferme la modal, voir comment faire si onUpdate est un state setter
     */
    onCreate(data);
    setShowCreateModal(false);
  };

  return (
    <>
      <Fab
        style={{ float: 'right', marginBlock: -50, marginRight: 10 }}
        color="primary"
        size="small"
        aria-label="add"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setShowCreateModal(true);
        }}
      >
        <AddIcon />
      </Fab>

      <ContractCreateModal
        contract={newContract}
        open={showCreateModal}
        setOpen={setShowCreateModal}
        onCreate={onCreateAndClose}
      />
    </>
  );
};

const ContractList = () => {
  const [contracts, setContracts] = useState<Array<IContract>>([]);

  /** Array of selected Contracts */
  const [selected, setSelected] = useState<Array<IContract>>([]);

  const classes = useStyles();
  const contentPaper = clsx(classes.paper, classes.contentHeight);

  /**
   * Get contracts
   */
  const getContracts = async () => {
    const listContracts = await ContractService.get();
    setContracts(listContracts);
  };
  useEffect(() => {
    getContracts();
  }, []);

  /**
   * Get one contract

  const getOneContract = async (contract) => {
    const oneContract = await ContractService.getOne(new IContract(contract));
    //setContracts(oneContract);
  };
   */

  /**
   * Mise à jour de la liste après création du contrat
   */
  const addContractToContractList = async (newContract: IContract) => {
    setContracts((prev: Array<IContract>) => [...prev, newContract]);
  };

  /**
   * Mise à jour de la liste après mise à jour du contrat
   */
  const updateList = async (newContract: IContract) => {
    setContracts((prev: Array<IContract>) =>
      prev.map((c: IContract) => (newContract.id === c.id ? newContract : c)),
    );
  };

  /**
   *
   * Suppression contrat
   */
  const deleteList = async (contract: IContract) => {
    setContracts((prev: Array<IContract>) => prev.filter((c) => contract.id !== c.id));
  };

  /**
   * Obtenir les lignes sélectionnées pour une action "bulk" ("en vrac" ou "groupée" )
   */
  const onSelectionModelChange = (selectionModel: GridSelectionModel, details?: any) => {
    setSelected(contracts.filter((c) => selectionModel.includes(c.id.toString())));
  };

  const { t } = useTranslation();

  const columns: GridColumns = [
    { field: 'id', headerName: 'Id', width: 130 },
    { field: 'num', headerName: t('contracts:field.num'), width: 130 },
    { field: 'tag', headerName: 'Tag', width: 130 },
    {
      field: 'beginDate',
      headerName: t('contracts:field.beginDate'),
      width: 130,
      renderCell: (params: GridCellParams) => {
        const { row } = params;
        return <Moment format="DD/MM/YYYY" date={row.endDate} />;
      },
    },
    {
      field: 'endDate',
      headerName: t('contracts:field.endDate'),
      width: 130,
      renderCell: (params: GridCellParams) => {
        const { row } = params;
        return <Moment format="DD/MM/YYYY" date={row.endDate} />;
      },
    },
    {
      field: 'price',
      headerName: t('contracts:field.price'),
      width: 130,
      renderCell: (params: GridCellParams) => {
        const { row } = params;
        return (
          <CurrencyTextField
            textAlign="center"
            disabled
            value={row.price}
            currencySymbol="€"
            margin="dense"
            fullWidth
            digitGroupSeparator=" "
          />
        );
      },
    },
    {
      field: 'contentUrl',
      headerName: t('contracts:field.contentUrl'),
      width: 130,
      renderCell: (params: GridCellParams) => {
        // recupère l'id depuis la row, récupère le contrat dans "contracts"
        // avec l'id en question
        const { row } = params;
        const contract = contracts.filter((c) => c.id === row.id)[0];
        const fileUrl = ContractService.download(contract);
        return (
          fileUrl && (
            <Link target="_blank" to={{ pathname: fileUrl }} download={true}>
              <Button color="primary" size="large" startIcon={<CloudDownloadIcon />} />
            </Link>
          )
        );
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 260,
      renderCell: (params: GridCellParams) => {
        // recupère l'id depuis la row, récupère le contrat dans "contracts"
        // avec l'id en question
        const { row } = params;
        const contract = contracts.filter((c) => c.id === row.id)[0];

        return (
          <>
            <ContractRowButtons contract={contract} onUpdate={updateList} onDelete={deleteList} />
          </>
        );
      },
    },
  ];

  return (
    <>
      <div style={{ height: '650px', width: '100%' }}>
        <Paper className={contentPaper}>
          <div>
            <h2>{t('contracts:label.title.list')}</h2>
            <ContractCreateButton onCreate={addContractToContractList} />
          </div>
          <DataGrid
            onSelectionModelChange={onSelectionModelChange}
            checkboxSelection
            disableSelectionOnClick
            rows={contracts}
            columns={columns}
          />
        </Paper>
      </div>
    </>
  );
};

export default ContractList;
